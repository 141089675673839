import React from 'react';
import classNames from 'classnames';
import Equalizer from 'react-equalizer';

import ProLink from '../../shared/ProLink/ProLink';
import ViewMore from '../../shared/ViewMore/ViewMore';
import { miniGazelle, isClient } from '../../../helpers';
import './ContentCard.scss';

class ContentCard extends React.Component {
    constructor() {
        super();
        this.state = {
            mobileWidth: false,
        };
    }

    componentDidMount() {
        if (isClient()) {
            // mobile / desktop styling
            (() => {
                // responsive switch mobileWidth
                const updateSize = () => {
                    if (document.body.clientWidth < 768) {
                        this.setState({ mobileWidth: true }, () => {});
                    } else {
                        this.setState({ mobileWidth: false }, () => {});
                    }
                };
                updateSize();
                window.addEventListener('resize', updateSize);
            })();
        }
    }

    render() {
        const { mobileWidth } = this.state;
        const { data = {} } = this.props;
        const {
            logicalName,
            removeTopPadding,
            removeBottomPadding,
            contents = [],
            theme = 'light',
            textColor,
            backgroundColor,
            backgroundImage,
            moduleType,
            viewMoreText,
            viewMoreLink,
        } = data;

        const wrapperClassNames = classNames({
            'content-card': true,
            module: true,
            'container-fluid': true,
            'remove-bottom-padding': removeBottomPadding,
            'remove-top-padding': removeTopPadding,
            [`theme-${theme}`]: theme,
        });

        const length = contents.length;

        const wrapperStyles = {
            color: textColor,
            backgroundColor,
            backgroundImage: backgroundImage ? `url('${backgroundImage}')` : null,
        };

        const contentMarkup = contents.map((content, i) => {
            const contentClass = classNames({
                'content-card-wrapper': true,
                'col-md-9': length < 2,
                'col-md-3': length >= 2,
                'text-column': true,
                'content-card-flex': length < 2,
            });

            const {
                title,
                description,
                titleAlignment,
                descriptionAlignment,
                image,
                imageAndTitleUrl,
                groupUrl,
                imageAlignment,
                readMore,
                readMoreAlignment,
            } = content;

            const titleClass = classNames({
                h3: true,
                'text-left': titleAlignment === 'left',
                'text-center': titleAlignment === 'center',
                'text-right': titleAlignment === 'right',
            });

            const descriptionClass = classNames({
                p: true,
                'inner-content': true,
                'text-left': descriptionAlignment === 'left',
                'text-center': descriptionAlignment === 'center',
                'text-right': descriptionAlignment === 'right',
            });

            const readMoreClass = classNames({
                'read-more-single': length < 2,
                'read-more': length >= 2,
                'inner-content': true,
                'text-left': readMoreAlignment === 'left',
                'text-center': readMoreAlignment === 'center',
                'text-right': readMoreAlignment === 'right',
            });

            if (moduleType === 'manual') {
                return (
                    <div key={i} className={contentClass}>
                        {imageAlignment === 'center' && (
                            <ProLink to={imageAndTitleUrl ? imageAndTitleUrl : groupUrl} className="block-link">
                                <div
                                    className={classNames({
                                        'content-wrapper-inner-manual': true,
                                        'content-padding': length >= 2,
                                    })}
                                >
                                    {image && image !== 'false' && (
                                        <img src={image} className="content-thumb" alt="" role="presentation" />
                                    )}
                                    {title && titleAlignment !== 'below' && <h3 className={titleClass}>{title}</h3>}
                                    {description && (
                                        <div className="truncate-safe">
                                            <p
                                                className={descriptionClass}
                                                dangerouslySetInnerHTML={{ __html: description }}
                                            />
                                        </div>
                                    )}
                                    {readMore && (
                                        <p className={readMoreClass} dangerouslySetInnerHTML={{ __html: readMore }} />
                                    )}
                                    {title && titleAlignment === 'below' && <h3 className={titleClass}>{title}</h3>}
                                    {(imageAndTitleUrl || groupUrl) && (
                                        <span className="sr-only">{miniGazelle.label('readMore', true)}</span>
                                    )}
                                </div>
                            </ProLink>
                        )}
                        {imageAlignment === 'left' && (
                            <ProLink to={imageAndTitleUrl ? imageAndTitleUrl : groupUrl} className="block-link">
                                <div
                                    className={classNames({
                                        'content-wrapper-inner-manual': true,
                                        'content-padding': length >= 2,
                                    })}
                                >
                                    <div
                                        className={classNames({
                                            row: true,
                                            'smort-center': mobileWidth === false,
                                        })}
                                    >
                                        <div className="col-sm-6">
                                            {image && image !== 'false' && (
                                                <img src={image} className="content-thumb" alt="" role="presentation" />
                                            )}
                                        </div>
                                        <div className="col-sm-6">
                                            {title && titleAlignment !== 'below' && (
                                                <h3 className={titleClass}>{title}</h3>
                                            )}
                                            {description && (
                                                <div className="truncate-safe">
                                                    <p
                                                        className={descriptionClass}
                                                        dangerouslySetInnerHTML={{ __html: description }}
                                                    />
                                                </div>
                                            )}
                                            {readMore && (
                                                <p
                                                    className={readMoreClass}
                                                    dangerouslySetInnerHTML={{ __html: readMore }}
                                                />
                                            )}
                                            {title && titleAlignment === 'below' && (
                                                <h3 className={titleClass}>{title}</h3>
                                            )}
                                            {(imageAndTitleUrl || groupUrl) && (
                                                <span className="sr-only">{miniGazelle.label('readMore', true)}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </ProLink>
                        )}
                        {imageAlignment === 'right' && (
                            <ProLink to={imageAndTitleUrl ? imageAndTitleUrl : groupUrl} className="block-link">
                                <div
                                    className={classNames({
                                        'content-wrapper-inner-manual': true,
                                        'content-padding': length >= 2,
                                    })}
                                >
                                    <div
                                        className={classNames({
                                            row: true,
                                            'smort-center': mobileWidth === false,
                                        })}
                                    >
                                        <div className="col-sm-6">
                                            {title && titleAlignment !== 'below' && (
                                                <h3 className={titleClass}>{title}</h3>
                                            )}
                                            {description && (
                                                <div className="truncate-safe">
                                                    <p
                                                        className={descriptionClass}
                                                        dangerouslySetInnerHTML={{ __html: description }}
                                                    />
                                                </div>
                                            )}
                                            {readMore && (
                                                <p
                                                    className={readMoreClass}
                                                    dangerouslySetInnerHTML={{ __html: readMore }}
                                                />
                                            )}
                                            {title && titleAlignment === 'below' && (
                                                <h3 className={titleClass}>{title}</h3>
                                            )}
                                            {(imageAndTitleUrl || groupUrl) && (
                                                <span className="sr-only">{miniGazelle.label('readMore', true)}</span>
                                            )}
                                        </div>
                                        <div className="col-sm-6">
                                            {image && image !== 'false' && (
                                                <img src={image} className="content-thumb" alt="" role="presentation" />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </ProLink>
                        )}
                    </div>
                );
            }

            if (moduleType === 'dynamic') {
                return (
                    <div key={i} className={contentClass}>
                        {imageAlignment === 'center' && (
                            <ProLink to={imageAndTitleUrl ? imageAndTitleUrl : groupUrl} className="block-link">
                                <div
                                    className={classNames({
                                        'content-wrapper-inner': true,
                                        'content-padding': length >= 2,
                                    })}
                                >
                                    {image && image !== 'false' && (
                                        <img src={image} className="content-thumb" alt="" role="presentation" />
                                    )}
                                    {title && titleAlignment !== 'below' && <h3 className={titleClass}>{title}</h3>}
                                    {description && (
                                        <div className="truncate-safe">
                                            <p
                                                className={descriptionClass}
                                                dangerouslySetInnerHTML={{ __html: description }}
                                            />
                                        </div>
                                    )}
                                    {readMore && (
                                        <p className={readMoreClass} dangerouslySetInnerHTML={{ __html: readMore }} />
                                    )}
                                    {title && titleAlignment === 'below' && <h3 className={titleClass}>{title}</h3>}
                                    <span className="sr-only">{miniGazelle.label('readMore', true)}</span>
                                </div>
                            </ProLink>
                        )}
                        {imageAlignment === 'left' && (
                            <ProLink to={imageAndTitleUrl ? imageAndTitleUrl : groupUrl} className="block-link">
                                <div
                                    className={classNames({
                                        'content-wrapper-inner': true,
                                        'content-padding': length >= 2,
                                    })}
                                >
                                    <div
                                        className={classNames({
                                            row: true,
                                            'smort-center': mobileWidth === false,
                                        })}
                                    >
                                        <div className="col-sm-6">
                                            {image && image !== 'false' && (
                                                <img src={image} className="content-thumb" alt="" role="presentation" />
                                            )}
                                        </div>
                                        <div className="col-sm-6">
                                            {title && titleAlignment !== 'below' && (
                                                <h3 className={titleClass}>{title}</h3>
                                            )}
                                            {description && (
                                                <div className="truncate-safe">
                                                    <p
                                                        className={descriptionClass}
                                                        dangerouslySetInnerHTML={{ __html: description }}
                                                    />
                                                </div>
                                            )}
                                            {readMore && (
                                                <p
                                                    className={readMoreClass}
                                                    dangerouslySetInnerHTML={{ __html: readMore }}
                                                />
                                            )}
                                            {title && titleAlignment === 'below' && (
                                                <h3 className={titleClass}>{title}</h3>
                                            )}
                                            {(imageAndTitleUrl || groupUrl) && (
                                                <span className="sr-only">{miniGazelle.label('readMore', true)}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </ProLink>
                        )}
                        {imageAlignment === 'right' && (
                            <ProLink to={imageAndTitleUrl ? imageAndTitleUrl : groupUrl} className="block-link">
                                <div
                                    className={classNames({
                                        'content-wrapper-inner': true,
                                        'content-padding': length >= 2,
                                    })}
                                >
                                    <div className="row">
                                        <div className="col-sm-6">
                                            {title && titleAlignment !== 'below' && (
                                                <h3 className={titleClass}>{title}</h3>
                                            )}
                                            {description && (
                                                <div className="truncate-safe">
                                                    <p
                                                        className={descriptionClass}
                                                        dangerouslySetInnerHTML={{ __html: description }}
                                                    />
                                                </div>
                                            )}
                                            {readMore && (
                                                <p
                                                    className={readMoreClass}
                                                    dangerouslySetInnerHTML={{ __html: readMore }}
                                                />
                                            )}
                                            {title && titleAlignment === 'below' && (
                                                <div className={titleClass}>{title}</div>
                                            )}
                                            {(imageAndTitleUrl || groupUrl) && (
                                                <span className="sr-only">{miniGazelle.label('readMore', true)}</span>
                                            )}
                                        </div>
                                        <div className="col-sm-6">
                                            {image && image !== 'false' && (
                                                <img src={image} className="content-thumb" alt="" role="presentation" />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </ProLink>
                        )}
                    </div>
                );
            }
        });

        return (
            <div id={logicalName} className={wrapperClassNames} style={wrapperStyles}>
                <div className="container">
                    <Equalizer byRow={true} className="row content-wrapper content-card-flex">
                        {contentMarkup}
                    </Equalizer>
                    {viewMoreText && <ViewMore linkText={viewMoreText} linkTarget={viewMoreLink} />}
                </div>
            </div>
        );
    }
}

export default ContentCard;
