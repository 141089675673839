import React from 'react';
import ProLink from '../ProLink/ProLink';
import classNames from 'classnames';
import { isClient } from '../../../helpers';

import * as styles from './ViewMore.module.scss';

/**
 * @component ViewMore
 *
 * @property {string} linkText - Text to pass to the component
 * @property {string} linkTarget - Path/URL to pass to the component
 *
 * @example <ViewMore linkText={yourTextVariable} linkTarget={yourLinkVariable} />
 */

class ViewMore extends React.Component {
    constructor() {
        super();
        this.state = { mobileWidth: false };
    }


    render() {
        const { linkTarget, linkText } = this.props;

        return (
            <ProLink to={linkTarget} className={styles.wrapper}>
                    <span>{linkText}</span>
                    <i className="icon-r-arrows" aria-hidden="true" />
            </ProLink>
        );
    }
}

export default ViewMore;
